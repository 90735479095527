import { html, css, LitElement } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { ref, createRef } from 'lit/directives/ref.js';
import { customElement, property, state } from 'lit/decorators.js';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import './hex-tile';
import  './settings-modal';
import  './sf-dropdown';
import  './sf-switch';
import  './pm-footer';

import { registerSW } from 'virtual:pwa-register';

export enum GridType {
  PointedUp = "pointed-up",
  FlatUp = "flat-up"
}
@customElement('pattern-maker')
export class PatternMaker extends LitElement {

  static styles = [css`
    :host {
      --primary-color: hsla(var(--primary-color-hue, 50), var(--primary-color-saturation, 100%), var(--primary-color-luminance, 50%), var(--primary-color-alpha, 1));
      --logo-secondary-fill: var(--primary-color);
      --highlight-color-alpha: 1;
      --highlight-color: rgba(239, 229, 169, var(--highlight-color-alpha));
      --shadow-color-alpha: 1;
      --shadow-color: rgba(40, 40, 40, var(--shadow-color-alpha));
      --row-count: 25;
      --column-count: 25;
      --hex-height: 55px;
      --hex-width: calc(var(--hex-height) * 1.7320508075688772 / 2);
      --grid-scale: 1;
      --cursor-style: default;
      display: block;
      position: relative;
      cursor: grab;
      max-width: calc(100vw);
      min-height: calc(100vh);
      box-sizing: border-box;
      overflow: hidden;
      margin-top: 80px;
      font-family: 'JosefinSans', Tahoma, Verdana, sans-serif;
      background-color: var(--highlight-color);
    }

    #svgGrid {
      transform: scale(var(--grid-scale));
      position: relative;
      border: 1px solid red;
      position: absolute;
      left: 0;
    }

    hex-tile {
      content-visibility: auto;
      contain-intrinsic-size: 86px 100px;
    }

    main {
      display: block;
      width:calc(100vw);
      height:calc(100vh - 80px - 25px);
      position: relative;
      flex-wrap: wrap;
      overflow: hidden;
      background-color: var(--highlight-color);
    }

    :host([panning]),
    :host([panning]) hex-tile {
      --cursor-style: grabbing;
      cursor: grabbing !important;
    }

    :host([isscaling]),
    :host([isscaling]) hex-tile {
      --cursor-style: zoom-in;
      cursor: zoom-in !important;
    }

    .label {
      display: flex;
      width: 100%;
      font-size: 12px;
      margin-top: .38em;
      justify-content: center;
    }


    :host([shouldSelectMany]) .selectBtn{
      font-weight: bold;
    }

    header {
      position: relative;
      height: 80px;
      width: 100vw;
      background-color: var(--primary-color);
      border-bottom: 3px solid var(--shadow-color);
      position: fixed;
      top: 0;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      z-index: 100;
      align-items: center;
      padding: 10px;
      box-sizing: border-box;
      align-items: center;
    }

    sf-dropdown a:hover,
    sf-dropdown a[selected] {
      background-color: var(--shadow-color);
      color: var(--highlight-color);
    }

    sf-dropdown a i,
    sf-dropdown a span {
      display: flex;
      width: max-content;
    }

    sf-dropdown a i {
      font-style: normal;
      font-size: 2.2em;
      width:  .5em;
      margin-right: .5em
    }

    .gridSettings {
      margin-left: auto;
      margin-right: 10px;
    }

    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      width: min-content;
      margin-left: .8em;
      color: var(--shadow-color);
      font-family: 'JosefinSans', Tahoma, Verdana, Segoe, sans-serif;
    }

    header > svg {
      width: 30px;
    }

    a[name="Oriole Gold"] i {
      color: rgb(257, 252, 14);
    }
    a[name="Sunset Orange"] i {
      color: rgb(252, 85, 33);
    }
    a[name="Burgundy Velvet"] i {
      color: rgb(147,1,1);
    }
    a[name="Bullfinch Pink"] i {
      color: rgb(222, 133, 165);
    }
    a[name="Peacock Blue"] i {
      color: rgb(66, 160, 236);
    }
    a[name="Starling Blue"] i {
      color: rgb(26, 63, 169);
    }
    a[name="Forest Green"] i {
      color: rgb(30, 112, 22);
    }
  `]

  @property({ type: Number })
  rows = 25

  @property({type: Number})
  columns = 25

  @property({ type: Number })
  currentScale = 1;

  @property({ type: String })
  toggleType = 'active' || 'color' || 'type';

  @property({ type: Array })
  colors = [
    {name: 'Oriole Gold', color: 'rgb(257, 252, 14)'},
    {name: 'Sunset Orange', color: 'rgb(252, 85, 33)'},
    {name: 'Burgundy Velvet', color: 'rgb(147,1,1)'},
    {name: 'Bullfinch Pink', color: 'rgb(222, 133, 165)'},
    {name: 'Peacock Blue', color: 'rgb(66, 160, 236)'},
    {name: 'Starling Blue', color: 'rgb(26, 63, 169)'},
    {name: 'Forest Green', color: 'rgb(30, 112, 22)'}
  ]

  @property({type: Object})
  currentColor = this.colors[2];

  @property({ type: String})
  currentType = 'pointed';

  @property({ type: Number })
  previousWheelPosition = 0;

  @property({ type: Number })
  padding = 20;

  @property({ type: Array })
  selectedTiles = [];

  @property({ type: Array })
  activeTiles = [];

  @property({ type: Boolean })
  hideGrid = false

  @property({ type: Boolean })
  hideGridSettings = true;

  @property({ type: Boolean , reflect: true})
  shouldSelectMany = false;

  @state()
  hammerInst;

  @state()
  gridType = GridType.PointedUp;

  @state()
  scaling= false;

  @property({ type: Boolean, reflect: true })
  isScaling = false;

  @property({ type: Boolean, reflect: true })
  panning = false;

  @state()
  MainEl;

  @state()
  PreviousTouch;


  connectedCallback() {
    super.connectedCallback()
    window.addEventListener('wheel', this.wheelHandler.bind(this));
    window.addEventListener('resize', this.updateDimensions.bind(this));
    window.addEventListener('keydown', this.setZoom.bind(this));
    window.addEventListener('keyup', this.unsetZoom.bind(this));


    requestAnimationFrame(()=>{
      this.MainEl = this.shadowRoot.querySelector('main');
      const newScrollLeft = (this.MainEl.scrollWidth - this.MainEl.clientWidth)/2;
      const newScrollTop = (this.MainEl.scrollHeight - this.MainEl.clientHeight)/2;
      this.MainEl.scrollLeft = newScrollLeft;
      this.MainEl.scrollTop = newScrollTop;
      this.MainEl.addEventListener('touchstart', this.touchStartHandler.bind(this), false);
      this.MainEl.addEventListener('touchmove', this.touchMoveHandler.bind(this), false);
      this.MainEl.addEventListener('touchend', this.touchEndHandler.bind(this), false);

      this.MainEl.addEventListener('mousedown', this.touchStartHandler.bind(this), false);
      this.MainEl.addEventListener('mousemove', this.touchMoveHandler.bind(this), false);
      this.MainEl.addEventListener('mouseup', this.touchEndHandler.bind(this), false);
    })
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener('wheel', this.wheelHandler.bind(this));
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    window.removeEventListener('keydown', this.setZoom.bind(this));
    window.removeEventListener('keyup', this.unsetZoom.bind(this));
    this.MainEl.removeEventListener('touchstart', this.touchStartHandler.bind(this), false);
    this.MainEl.removeEventListener('touchmove', this.touchMoveHandler.bind(this), false);
    this.MainEl.removeEventListener('touchend', this.touchEndHandler.bind(this), false);

    this.MainEl.removeEventListener('mousedown', this.touchStartHandler.bind(this), false);
    this.MainEl.removeEventListener('mousemove', this.touchMoveHandler.bind(this), false);
    this.MainEl.removeEventListener('mouseup', this.touchEndHandler.bind(this), false);
  }

  firstUpdated() {
    registerSW({ immediate: true });
    requestAnimationFrame(()=>{
      this.updateDimensions();

    });
  }

  updateDimensions(){
      let hexDimension = this.shadowRoot.querySelector('hex-tile').shadowRoot.querySelector('path').getBoundingClientRect();
      this.style.setProperty('--hex-height', `${hexDimension.height}px`);
      this.style.setProperty('--hex-weight', `${hexDimension.width}px`);
  }

  wheelHandler(event){
    if (!event.metaKey) return;

    this.updateScale(event, event.deltaY);
  }

  touchStartHandler(event){
    const { clientX, clientY } = event;
    if (event.touches?.length === 2) {
      event.preventDefault();
      this.scaling = true;
    }
    this.panning = true;
    this.PreviousTouch = event.touches ? event.touches[0]: {clientX, clientY};
  }

  touchMoveHandler(event){
    event.preventDefault();
    const { clientX, clientY } = event;
    if (this.scaling) {
      this.pinchHandler(event);
    }
    if (this.panning && !this.scaling){
      this.panHandler(event);
      this.PreviousTouch = event.touches ? event.touches[0] : { clientX, clientY };
    }
  }

  touchEndHandler(_event){
    this.scaling = false;
    this.panning = false;
  }

  setZoom(event){
    if (!event.metaKey) return;

    this.isScaling = true;
  }

  unsetZoom(_event) {

    this.isScaling = false;
  }

  pinchHandler(event) {
    event.preventDefault();
    this.updateScale(event, event.scale);
  }

  panHandler(event) {
    let pointerObj = !!event.clientX ? event : event.touches[0];
    let deltaX = pointerObj.clientX - this.PreviousTouch.clientX;
    let deltaY = pointerObj.clientY - this.PreviousTouch.clientY;
    this.MainEl.scrollLeft = this.MainEl.scrollLeft - deltaX;
    this.MainEl.scrollTop = this.MainEl.scrollTop - deltaY;
  }

  updateScale(_event, marker) {

    //Grabs all relevant values
    let currentScale = this.currentScale;
    let factor = marker <= 1 ? .99 : 1.01;

    //Calculates the new scale depending on scale and factor
    let newScale = currentScale * factor;
    newScale = Math.min(6, newScale); //Caps newscale at 6
    newScale = Math.max(.2, newScale); //Sets min size to .6

    //Sets the new scale
    this.currentScale = newScale;
    this.style.setProperty("--grid-scale", `${newScale}`);

  }

  setColor(colorPosition: number){
    let { colors } = this;
    this.currentColor = colors[colorPosition];
    this.updateSelectedTiles('color');
  }

  updateType(event: any){
    this.currentType = event.detail.active ? 'pointed' : 'flat';
    this.updateSelectedTiles('type');
  }

  toggleGridSetting(){
    let modal = this.shadowRoot.querySelector('settings-modal');
    // @ts-ignore
    modal.hidden = !modal.hidden;
  }

  deselectAll(): void{
    this.selectedTiles.forEach((tile)=>{
      tile.selected = false;
      if (!tile.active) {
        tile.type = undefined;
      }
    })
    this.selectedTiles = [];
  }

  toggleGridType(){
    this.gridType = this.gridType === GridType.PointedUp ? GridType.FlatUp : GridType.PointedUp;
  }

  beforeUnloadListener (event){
    event.preventDefault();
    return event.returnValue = "Are you sure you want to exit, without saving your design?";
  }

  checkUnload(){
    if (this.activeTiles.length > 0) {
      window.addEventListener("beforeunload", this.beforeUnloadListener, { capture: true });
    } else {
      window.removeEventListener("beforeunload", this.beforeUnloadListener, { capture: true });
    }
  }

  removeFromSelected(tile){
    let { selectedTiles } = this;
    if (!tile.active){
      tile.type = undefined;
    }

    const tileIndex = selectedTiles.indexOf(tile)
    if (tileIndex > -1) {
      selectedTiles.splice(tileIndex, 1);
      selectedTiles = selectedTiles.slice();
    }
  }

  selectTile(event: any){
    console.log("🚀 ~ file: pattern-maker.ts:436 ~ PatternMaker ~ selectTile ~ event", event)
    const SvgElement = event.target;
    if (event.metaKey || this.shouldSelectMany){
      this.selectedTiles.push(SvgElement);
    }else{
      this.deselectAll();
      this.selectedTiles.push(SvgElement);
    }

    SvgElement.selected = !SvgElement.selected;
    if (!SvgElement.selected){
      this.removeFromSelected(SvgElement);
    }

    const WasActive = SvgElement.active;
    SvgElement.active = (event.metakey || this.shouldSelectMany) && SvgElement.active  ? true : !SvgElement.active;

    if (SvgElement.active && !WasActive){
      SvgElement.type = this.currentType;
      SvgElement.color = this.currentColor.color;
    } else if (SvgElement.active && WasActive){
    }else{
      this.deselectAll();
    }

    requestAnimationFrame(()=>{
      this.getActiveTiles();
      this.checkUnload();
    });
  }

  updateSelectedTiles(param){
    let { selectedTiles  } = this;
    selectedTiles.forEach((tile) => {
      let { currentColor, currentType } = this;
      if(tile.selected){
        if (param === 'type'){
          tile.type =  currentType;
        }

        if (param === 'color') {
          tile.color = currentColor.color;
        }
      }
    });
    this.getActiveTiles();
    this.checkUnload();
  }

  toggleHideGrid(event: any){
    this.hideGrid = event.detail.hideGrid;
  }

  colorList(){
    const colorListTemplate = [];
    for (var i = 0; i < this.colors.length; i++) {
      let index = i;
      colorListTemplate.push(html`<a
        @click="${() => { this.setColor(index); }}"
        ?selected="${this.colors[i] === this.currentColor}"
        name="${this.colors[i].name}">
          <i>⬢</i><span>${this.colors[i].name}</span>
        </a>`)
    }
    return colorListTemplate;
  }

  updatePadding(event: any){
    const rangeValue = event.detail.padding;
    this.padding = parseInt(rangeValue)
  }

  toggleSelectMany() {
    this.shouldSelectMany = !this.shouldSelectMany;
  }

  getActiveTiles(){
    let { shadowRoot } = this;
    this.activeTiles =  Array.from(shadowRoot.querySelectorAll('hex-tile[active]'));
  }

  renderSelectTxt(){
    if (this.shouldSelectMany){
      return 'Selecting Many';
    }
    return 'Select Many';
  }

  renderGridTxt(){
    if (this.gridType === GridType.PointedUp){
      return 'Pointed Sides up';
    }
    return 'Flat Sides up';
  }

  save(){
    this.deselectAll();
    let hexGrid = this.shadowRoot.querySelector("main");
    // @ts-ignore
    html2canvas(hexGrid).then( (canvas)=>{
      canvas.toBlob((blob)=>{
        saveAs(blob, 'Meine-Hexagonal-Design-Schnauze-Fabrik.png');
        window.removeEventListener("beforeunload", this.beforeUnloadListener, { capture: true });

      })
    });
  }

  render() {
    const { currentColor, columns, rows, hideGrid, gridType, padding, currentType } = this;
    return html`
      <header>

        <sf-dropdown btncolor="${currentColor.color}" title="Select Color">
          ${this.colorList()}
        </sf-dropdown>

        <sf-switch @activeUpdated="${this.updateType}"></sf-switch>
        <button class="headerBtn selectBtn" @click="${this.toggleSelectMany}">${this.renderSelectTxt()}</button>
        <button class="headerBtn" @click="${this.deselectAll}">Deselect All</button>
        <button class="headerBtn" @click="${this.toggleGridType}">${this.renderGridTxt()}</button>
        <button
          class="gridSettings"
          name="Grid Settings"
          @click="${this.toggleGridSetting}">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 850.4 850.4" viewBox="0 0 850 850">
            <path d="M714 747 528 639V425l186-107 136 78v-17l-129-74V90l129-74V0h-4L716 75 586 0h-39l155 90v214L517 411 331 304V89L486 0h-34L324 74 195 0h-41l157 91v214L125 412 0 340v16l120 69v214L0 708v17l124-71 186 107v89h20v-89l185-107 186 107v89h22v-89l127-74v-19l-136 79zM509 639 323 747 138 639V425l185-107 186 107v214zM96 0H62L0 36v20z" style="fill:#020202"/>
          </svg>
          <span class="label">Grid</span>
        </button>
        <settings-modal
          @toggleHideGrid="${this.toggleHideGrid}"
          @updatePadding="${this.updatePadding.bind(this)}">
        </settings-modal>

      </header>
      <main>
        <section id="svgGrid">
          ${repeat(
            [...Array(rows).keys()],
            (row) => {  return `row${row}` },
            (row) => {
              return html`
                 ${repeat([...Array(columns).keys()],
                (column) => {  return `row${row}column${column}` },
                (column) => {
                  const tileRef = createRef();
                  return html`<hex-tile
                    ${ref(tileRef)}
                    ?hideGrid="${hideGrid}"
                    @click="${this.selectTile}"
                    column="${column}"
                    row="${row}"
                    size="55"
                    gridtype="${gridType}"
                    spacingfactor="${padding}"
                    currentType="${currentType}">`
                })}
              `;
            })}
        </section>
      </main>
      <pm-footer @save="${this.save}" .tiles="${this.activeTiles}">
      </pm-footer>
    `
  }
}